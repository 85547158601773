import React from "react"
import { Link } from "gatsby"
import Button from "@bit/azheng.joshua-tree.button"
import Layout from "../components/layout"
import SEO from "../components/seo"
import TheaterVideo from "@components/Theater/TheaterVideo"
const wisdomTeethData = require("../_archives/procedures/wisdom-teeth-reno.json")

const W4W = () => {
  const image = name =>
    `https://res.cloudinary.com/nuvolum/image/upload/q_99,f_auto/v1/Programs/${name}`
  return (
    <Layout pageTitle="wisdom-page" language={"en"}>
      <SEO
        lang="en"
        title="Learn about our Wisdom For Wisdom program"
        description="Follow the Wisdom For Wisdom steps to apply"
      />
      <div className="w4w-22">
        <div className="w4w-22__hero">
          <img
            src={image("w4w-top-left-corner-image")}
            className="w4w-22__hero-tl w4w-22__shapes-img"
            alt="color shapes"
          />
          <img
            src={image("2022-w4w-logo")}
            className="w4w-22__hero-middle"
            alt="wisdom for wisdom logo"
          />
          <img
            src={image("w4w-top-right-corner-image")}
            className="w4w-22__hero-tr w4w-22__shapes-img"
            alt="colorful shapes"
          />
        </div>

        <div className="w4w-22__about">
          <div className="w4w-22__about-content">
            <h1>Making College More Affordable</h1>
            <h2>
              College costs are higher than ever. We’re doing our part to help
              local families save money.
            </h2>
            <p>
              Our 2022 Wisdom For Wisdom program helped to offset some of the
              costs of college by providing free wisdom teeth removal to a
              student in or around Reno and Sparks.
            </p>
            <p>
              This year our recipient is Jacquelyn, 22, from Sun Valley. She is
              a student in the nursing program at Truckee Meadows Community
              College and works part-time as a critical care tech. In her
              application, she wrote that for many years she had a fear of going
              to the dentist, but recently started going again and is now back
              on track with her dental health. She continued by saying she was
              very proud of herself for taking the initiative and wrote how she
              hopes to set a good example for her daughter, showing how
              important it is to take care of yourself and your health. As she
              continues to take control of her oral health, she now needs to
              have her wisdom teeth removed, especially as some have decay and
              others are impacted.
            </p>
            <p>
              We look forward to helping Jacquelyn continue to focus on her oral
              health as she prepares to help others by becoming a nurse.
            </p>
          </div>
        </div>

        <div className="w4w-22__apply">
          <img
            src={image("w4w-mid-left-image")}
            className="w4w-22__apply-tl w4w-22__shapes-img"
            alt="color shapes"
          />
          <div className="w4w-22__apply-content">
            <h3>
              Follow along with Jacquelyn’s treatment journey on{" "}
              <Link
                to="https://www.facebook.com/northernnevadaoms/"
                title="Visit our Facebook"
                target="_blank"
              >
                Facebook
              </Link>{" "}
              and{" "}
              <Link
                to="https://www.instagram.com/northernnevadaoms/"
                title="Visit our Instagram"
                target="_blank"
              >
                Instagram
              </Link>
              .
            </h3>
            <p>
              <i>
                Learn more about{" "}
                <a href="oral-surgery-procedures/wisdom-teeth-removal-reno-nv/">
                  wisdom teeth removal
                </a>
                .
              </i>
            </p>

            <div className="w4w-22__facts">
              <h3>Just the facts...</h3>
              <ul>
                <li>
                  Wisdom teeth are the only teeth that don’t form before birth.
                </li>
                <li>Wisdom teeth are also known as third molars.</li>
                <li>
                  Impacted wisdom teeth can cause infections, damage to other
                  teeth, and cysts.
                </li>
                <li>
                  Wisdom teeth got their nickname because they often grow in
                  during the “age of wisdom” usually between the ages of 17 and
                  25.
                </li>
                <li>
                  Wisdom teeth growing in at the wrong angle may press against
                  other teeth, causing them to become misaligned.
                </li>
              </ul>
            </div>
          </div>
          <img
            src={image("w4w-bottom-right-image")}
            className="w4w-22__apply-br w4w-22__shapes-img"
            alt="color shapes"
          />
        </div>
      </div>
    </Layout>
  )
}

export default W4W
